<!--
 * @Description: 选择场景类型弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-23 16:44:06
 * @LastEditTime: 2022-07-01 09:25:04
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 添加任务项 -->
  <el-dialog
    v-dialogDrag
    title="选择场景"
    :visible.sync="selectSceneDialog"
    width="750px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    custom-class="write-dialog"
  >
    <section>
      <el-form class="search-list" ref="searchForm" :model="searchForm">
        <el-form-item class="search-item--1" prop="sceneName">
          <el-input
            placeholder="输入场景名称搜索"
            class="search-input"
            v-model="searchForm.sceneName"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch"
            ></el-button>
          </el-input>
        </el-form-item>
      </el-form>
    </section>
    <section class="table-wrapper">
      <el-table
        :data="sceneNameList"
        height="500"
        highlight-current-row
        @selection-change="handleSelect"
        ref="dialogTable"
        :row-key="
          row => {
            return row.sceneId;
          }
        "
      >
        <el-table-column
          type="selection"
          :reserve-selection="true"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in taskcolumnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </section>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="pageSize"
        :current-page.sync="pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消 </el-button>
      <el-button type="primary" @click="confirmSelect">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { getSceneNameList } from "@/api";
import { printError } from "@/utils/util";
import listPage from "@/mixins/list-page";
export default {
  name: "selectSceneType",
  components: {},
  data() {
    return {
      searchForm: {
        sceneName: ""
      },
      pageSize: 10,
      pageIndex: 1,
      total: 0,
      taskcolumnData: [
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      sceneNameList: [], //表格数据
      selectData: [] //选中的弹框数据
    };
  },
  props: ["selectSceneDialog", "corpId"],
  mixins: [base, listPage],
  computed: {},
  watch: {
    selectSceneDialog(val) {
      if (val) {
        this.getSceneNameListReq();
      }
    }
  },
  methods: {
    handleSearch() {
      this.pageIndex = 1;
      this.getSceneNameListReq();
    },
    //获取弹框列表
    async getSceneNameListReq() {
      try {
        let res = await getSceneNameList({
          corpId: this.corpId,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          ...this.searchForm
        });
        this.sceneNameList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.dialogTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 页码改变
    handleCurrentChange(curPage) {
      this.pageIndex = curPage;
      this.getSceneNameListReq();
      this.scrollTop = 0;
    },
    // 每页条数改变
    handleSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getSceneNameListReq();
    },
    //多选数据
    handleSelect(rows) {
      this.selectData = rows;
    },
    //点击确定
    confirmSelect() {
      this.$emit("confirmSelect", _.cloneDeep(this.selectData));
      this.$refs.dialogTable.clearSelection();
      this.selectData = [];
      this.handleClose();
    },
    //关闭弹框
    handleClose() {
      this.$emit("handleClose", "selectSceneDialog");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.write-dialog {
  .el-dialog__body {
    max-height: 600px;
  }
}
</style>
